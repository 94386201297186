import React from 'react';
import { FooterLinks } from './components/FooterLinks';
import { HeaderMiddle } from './components/HeaderMiddle';
import Homepage from './pages/Homepage';
import { Routes, Route, Link } from "react-router-dom";
import Reactpage from './pages/Reactpage';
import Designpage from './pages/Designpage';


function App() {
  const headerLinks = [{link:'/', label: 'Home'}, {link: '/react-projects', label: 'React'}, {link:'/design-projects', label: 'Designs'} ]
  const footerLinks = [{title: 'Quick Links', links: headerLinks}]
  return (
    <>
      <HeaderMiddle links={headerLinks}/>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="react-projects" element={<Reactpage />} />
        <Route path="design-projects" element={<Designpage />} />
      </Routes>
      <FooterLinks data={footerLinks} />

    </>
  );
}

export default App;
