import { useEffect, useState } from 'react';
import { createStyles, Header, Group, ActionIcon, Container, Burger,Text, Transition, Paper  } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconMail, IconDiscountCheck} from '@tabler/icons';
import MVLogo from '../svgs/MVLogo';
import { Link, useNavigate, useLocation} from 'react-router-dom'
import HireMe from '../svgs/HireMe';
const HEADER_HEIGHT = 56;

const useStyles = createStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: HEADER_HEIGHT,

    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'flex-start',
    },
  },

  links: {
    width: 260,

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  social: {
    width: 260,

    [theme.fn.smallerThan('sm')]: {
      width: 'auto',
      marginLeft: 'auto',
    },
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 1,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',
    marginTop: 15,

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    marginRight: theme.spacing.md,

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: 20,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
    [theme.fn.largerThan('sm')]: {
      padding: '8px 12px',
    },
  },
  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}));

interface HeaderMiddleProps {
  links: { link: string; label: string }[];
}

export function HeaderMiddle({ links }: HeaderMiddleProps) {
  const [opened,  handlers] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const { classes, cx } = useStyles();
  const go = useNavigate();
  const path = useLocation().pathname;
  
  

  const items = links.map((link) =>{  
    return (
    <Link
      key={link.label}
      to={link.link}
      className={cx(classes.link, { [classes.linkActive]: active === link.link })}
    >
      {link.label}
    </Link>
  )});
    
  useEffect(()=>{
    handlers.close();
  },[path])

  useEffect(()=>{
    
    for(let i in links)
      if(path === links[i].link) setActive(links[i].link);
    
  },[items])

  return (
    <Header height={56} mb={15}>
      <Container className={classes.inner}>
        <Burger opened={opened} onClick={(event)=>{event.preventDefault();handlers.toggle();}} size="sm" className={classes.burger} />
        <Group className={classes.links} spacing={5}>
          {items}
        </Group>
        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>

        <Group position="center" align="center" spacing={3}>
          <MVLogo size={.25} />
          <Text size="lg" weight={900}>Mark Vainauskas</Text>
        </Group>
        
        
        <Group spacing={0} className={classes.social} position="right" noWrap>
          <ActionIcon size="xl">
            <IconMail size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="xl" component='a' href='https://www.upwork.com/freelancers/~01e5119bfe3b9e655e' >
            <HireMe size={1} color="#000C66"/>
          </ActionIcon>
          {/*<ActionIcon size="lg">
            <IconBrandTwitter size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandYoutube size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandInstagram size={18} stroke={1.5} />
          </ActionIcon>
          */}
        </Group>
      </Container>
    </Header>
  );
}