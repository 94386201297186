import React from 'react'
import { BlankPage } from '../components/BlankPage'

const Reactpage = () => {
  return (
    <>
    <BlankPage title="Hi" subTitle="This page is coming soon" msg="please check back later" description="somethings are in the works" btnName="Go to homepage" />
</>
  ) 
}

export default Reactpage