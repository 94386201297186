import * as React from "react"
import { SVGProps } from "react"

const MVLogo = ({ size = 1 }: any) => {
    return (
        <svg
            width={115*size}
            height={127*size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 115 127"
        >
            <path
                d="M5.974 68.353c1.418-.332 3.032-.287 4.842.135 1.84.392 3.303.86 4.39 1.403a891.611 891.611 0 0 0-3.35 23.714 635.186 635.186 0 0 0-2.67 24.03l-2.443-2.715c2.383-1.237 5.234-2.64 8.553-4.209a288.571 288.571 0 0 1 10.183-4.571 253.496 253.496 0 0 1 9.322-3.892c2.776-1.086 4.827-1.75 6.155-1.991.814.875 1.734 2.127 2.76 3.756 1.026 1.629 1.81 2.987 2.353 4.073a677.668 677.668 0 0 0-19.821 8.508 325.018 325.018 0 0 0-20.093 9.685L0 119.762c.453-4.616.935-9.564 1.448-14.844a869.755 869.755 0 0 1 1.63-15.386 653.63 653.63 0 0 1 1.629-13.124c.482-3.802.905-6.487 1.267-8.055ZM30.576 8.904c2.202-1.478 4.163-2.534 5.883-3.168 1.75-.664 3.274-.98 4.57-.95a545.695 545.695 0 0 1 12.672 30.049c3.922 10.137 7.452 19.972 10.59 29.506 3.168 9.504 5.868 18.358 8.1 26.565 2.263 8.176 3.953 15.296 5.069 21.36-1.539.935-3.334 1.735-5.385 2.398-2.022.634-3.741.996-5.16 1.087-1.297-5.823-2.956-12.28-4.977-19.37a562.451 562.451 0 0 0-6.834-22.31 814.423 814.423 0 0 0-8.01-23.216A894.525 894.525 0 0 0 38.677 28.5c-2.806-7.15-5.506-13.682-8.101-19.595ZM65.592 18.962a71.838 71.838 0 0 1-.226-3.123c-.03-1.176 0-2.323.09-3.44.06-1.146.196-2.096.407-2.85.755-.453 2.565-1.056 5.431-1.81 2.866-.755 6.35-1.584 10.454-2.49a466.496 466.496 0 0 1 12.897-2.76A432.104 432.104 0 0 1 107.543 0l7.422 7.15c-1.478 4.677-3.062 9.61-4.752 14.799a1007.054 1007.054 0 0 1-4.933 14.617 818.376 818.376 0 0 1-4.027 11.54c-1.086 3.077-1.795 4.902-2.127 5.476-.453-.03-1.056-.181-1.81-.453a27.817 27.817 0 0 1-2.444-1.086 54.319 54.319 0 0 1-2.399-1.312c-.694-.453-1.176-.815-1.448-1.086.302-.664.77-2.067 1.403-4.21.634-2.202 1.388-4.857 2.263-7.964.905-3.138 1.855-6.502 2.85-10.092.966-3.62 1.932-7.21 2.897-10.77a630.45 630.45 0 0 0 2.67-9.911l2.082 3.801a368.726 368.726 0 0 0-9.006 1.946c-3.59.754-7.346 1.554-11.268 2.399a1465.58 1465.58 0 0 0-11.042 2.353c-3.44.724-6.2 1.312-8.282 1.765Z"
                fill="#000"
            />
        </svg>
    )

}


export default MVLogo