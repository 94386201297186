import React, { useCallback, useEffect, useState } from 'react'
import { createStyles, Card, Image, Text, Group, RingProgress, Anchor, Button } from '@mantine/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing.sm}px ${theme.spacing.lg}px`,
    borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
  },
}));

interface CardWithStatsProps {
  image: string;
  title: string;
  description: string;
  completion: string;
  netlifylink: string;
  domainlink: string;
  stats: {
    title: string;
    value: string;
  }[];
}

export function CardWithStats({ image, title, description, completion, stats, netlifylink, domainlink }: CardWithStatsProps) {
  const { classes } = useStyles();

  const items = stats.map((stat) => (
    <div key={stat.title}>
      <Text size="xs" color="dimmed">
        {stat.title}
      </Text>
      <Text weight={500} size="sm">
        {stat.value}
      </Text>
    </div>
  ));

  const links = (
    <Group style={{ width: '100%' }} grow={true}>
      <Anchor href={`https://${netlifylink}`} target="_blank"><Button variant='outline' style={{ width: '100%' }} >Design</Button></Anchor>
      <Anchor href={domainlink} target="_blank"><Button variant='outline' style={{ width: '100%' }}>Live</Button></Anchor>
    </Group>
  )

  return (
    <Card withBorder p="lg" className={classes.card}>
      <Card.Section>
        <LazyLoadImage
          height={'100%'}
          width="100%"
          src={image}
          style={{ objectFit: 'cover' }} />
      </Card.Section>

      <Card.Section px={10}>
        <Group position="apart" mt="xl" >
          <Text size="sm" weight={700} className={classes.title} style={{ height: '100%' }}>
            {title}
          </Text>
          <Group spacing={5}>
            <Text size="xs" color="dimmed">
              {completion}
            </Text>
            <RingProgress size={18} sections={[{ value: 80, color: 'blue' }]} />
          </Group>
        </Group>
        <Text mt="sm" mb="md" color="dimmed" size="xs">
          {description}
        </Text>
      </Card.Section>
      <Card.Section className={classes.footer}>{links}</Card.Section>
      <Card.Section className={classes.footer}>{items}</Card.Section>
    </Card>
  );
}