
import React, { useCallback, useEffect, useState } from 'react'
import { SimpleGrid, Stack, Text } from '@mantine/core'
import { CardWithStats } from '../components/CardWithStats'
import { clampUseMovePosition } from '@mantine/hooks'
import { BoxLoading } from 'react-loadingg';
import {useLocation} from 'react-router-dom';



interface DataObject {
    name: string
    description: string
    completion: string
    site: string
    stats: {
        title: string;
        value: string;
      }[];
}
interface WebsiteObject {
    id: string
    url: string
    photo: string
    created: string
    updated: string
    data?: DataObject
}
function getSites(){
    //get json file here
}

function Homepage() {
    
    const [websites, setWebsites] = useState<WebsiteObject[]>([]);
    //const url = `http://localhost:3000${useLocation().pathname}`;


    useEffect(()=>{
        async function fetchData(){
           let response = await fetch(`data.json?v=${Date.now()}`);
           let json = await response.json();
           setWebsites(json[0]);
        }
        fetchData()
    },[])

    const ProjectGrid = useCallback(() => {
        return (
            <SimpleGrid
                cols={4}
                spacing="lg"
                breakpoints={[
                    { maxWidth: 'md', cols: 3, spacing: 'md' },
                    { maxWidth: 'sm', cols: 2, spacing: 'sm' },
                    { maxWidth: 'xs', cols: 1, spacing: 'sm' },
                ]}
            >
                {Object.values(websites).map((value, index) => {
                    
                   return( <CardWithStats key={value.id} image={value.photo} title={value.data!.name} description={value.data!.description} netlifylink={value.url} domainlink={value.data!.site} completion={value.data!.completion} stats={value.data!.stats} />);
                })}
            </SimpleGrid>
        )
    }, [websites]);

    return (
        <>
            {websites.length > 0 ?
            <ProjectGrid/>
            :
            <Stack align="center" justify="center" style={{position:'relative'}}>
                <BoxLoading size="large" speed={.75} color="#000C66" style={{position:'relative'}}/>
                <Text color="dimmed" weight={400}>getting the good stuff...</Text>
            </Stack>

            }
        </>
    )
}

export default Homepage